var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-kpi"},[_vm._m(0),_c('Header'),_c('div',{staticClass:"moderator-user-kpi__content main-color main-content"},[_c('div',{staticClass:"moderator-user-kpi__row"},[_c('h5',{staticClass:"moderator-user-kpi__name"},[_vm._v(" KPI "+_vm._s(_vm.currentUser.surname)+" "+_vm._s(_vm.currentUser.name)+" ")])]),_c('div',{staticClass:"moderator-user-kpi__row"},[_vm._m(1),_c('div',{staticClass:"moderator-user-kpi__column"},[_vm._m(2),_c('div',{staticClass:"moderator-user-kpi__value"},[_c('p',[_vm._v(_vm._s("14 300".toLocaleString()))])])])]),_c('div',{staticClass:"moderator-user-kpi__row"},[_c('div',{staticClass:"moderator-user-kpi__column moderator-user-kpi__column_direction"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"moderator-user-kpi__graph"},[_c('line-chart',{staticClass:"moderator-user-kpi__line",style:([
              _vm.Container.width < 1068 && _vm.$route.query.period == 'month'
                ? { width: '1728px' }
                : _vm.Container.width <= 850 && _vm.$route.query.period == 'year'
                ? { width: '768px' }
                : _vm.Container.width <= 632 && _vm.$route.query.period == 'week'
                ? { width: '600px' }
                : false,
            ]),attrs:{"styles":{ height: '300px' }}})],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-kpi__title"},[_c('h4',[_vm._v("ЛК модератора")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-kpi__column"},[_c('div',{staticClass:"moderator-user-kpi__parameter"},[_c('p',[_vm._v("Обработано")])]),_c('div',{staticClass:"moderator-user-kpi__value"},[_c('p',[_vm._v("14 300")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-kpi__parameter"},[_c('p',[_vm._v("Жалобы")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-user-kpi__subtitle"},[_c('h5',[_vm._v("Продуктивность за неделю")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"moderator-user-kpi__list"},[_c('li',{staticClass:"moderator-user-kpi__status"},[_c('p',[_vm._v("Одобрено")])]),_c('li',{staticClass:"moderator-user-kpi__status"},[_c('p',[_vm._v("Отклонено")])])])
}]

export { render, staticRenderFns }